import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0,1,2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'MOFI',
    lpAddresses: {
      97: '0xe01D73B83584CbE2268642CF3b2c66a3A7A7556a',
      56: '0xe01D73B83584CbE2268642CF3b2c66a3A7A7556a',
    },
    token: tokens.syrup,
    quoteToken: tokens.wbnb,
  },
 
 
  {
    pid: 1,
    lpSymbol: 'MOFI-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xb8616321189926f45ae683d46663e2a8674d892e',
    },
    token: tokens.mofi,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 2,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x3a1361f6c01324d2cd693e6d23f37749f4e1d584',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 3,
    lpSymbol: 'ACT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x46df9422f08ae0c367973eed4a62a33f4b118e4f',
    },
    token: tokens.act,
    quoteToken: tokens.wbnb,
  },
  
   {
    pid: 4,
    lpSymbol: 'PTY-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xef547ca37b558339be8212498489a85349e5e63d',
    },
    token: tokens.pty,
    quoteToken: tokens.wbnb,
  },
  
  {
    pid: 5,
    lpSymbol: 'ACT-PTY LP',
    lpAddresses: {
      97: '',
      56: '0x1136e871eb67d58c6f5bf0223f3ce82f1f911307',
    },
    token: tokens.act,
    quoteToken: tokens.pty,
  },
  
  {
    pid: 6,
    lpSymbol: 'USDT-WBNB LP',
    lpAddresses: {
      97: '',
      56: '0x1ec6c68147a2953156474370a2763ee1c7ecb60d',
    },
    token: tokens.usdt,
    quoteToken: tokens.wbnb,
  },
  
 {
    pid: 7,
    lpSymbol: 'USDT-USDC LP',
    lpAddresses: {
      97: '',
      56: '0x7253640894458848eae26eac937fdc1e8dd09784',
    },
    token: tokens.usdt,
    quoteToken: tokens.usdc,
  },
  
  
  
  
  
   
	  
	
// finished last  2  
    

]

export default farms
