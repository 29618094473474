import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
   
	
		
   {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0xe01D73B83584CbE2268642CF3b2c66a3A7A7556a',
      },
      {
        label: t('Liquidity'),
        href: '/pool',

      },
    ],
  },
 
 
     {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },  
 
	
	 {
		label: t('More'),
		icon: 'MoreIcon',

		items: [
				{
				label: t('Info'),
				icon: 'InfoIcon',
				href: 'https://info.moonlight.finance',
				},
				
				
				
				{
				label: t('Docs'),
				icon: 'InfoIcon',
				href: 'https://docs.moonlight.finance/',
				}, 
				
				
				
	],}, 

  
       
 
	
	
	
	
	

]

export default config
