import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [

  {
    sousId: 0,
    stakingToken: tokens.mofi,
    earningToken: tokens.mofi,
    contractAddress: {
      97: '0x6d2f53e5a767ba6de9042aad342e50b117cc4d6b',
      56: '0x6d2f53e5a767ba6de9042aad342e50b117cc4d6b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.014945',
    sortOrder: 1,
    isFinished: false,
  },
  
   {
    sousId: 3,
    stakingToken: tokens.act,
    earningToken: tokens.mofi,
    contractAddress: {
      97: '',
      56: '0xa3105ea9ebfa8f1026a780135cf6475a3f0b3ecb',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0034762456',  
    },
	
	{
    sousId: 4,
    stakingToken: tokens.pty,
    earningToken: tokens.mofi,
    contractAddress: {
      97: '',
      56: '0x2f2653bb916664cc857c82a72d195ceb012424d4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0034762456',  
    },
	
	{
    sousId: 1,
    stakingToken: tokens.act,
    earningToken: tokens.mofi,
    contractAddress: {
      97: '',
      56: '0x20e39d27e6adde4e84eff699204a0a57026de65b',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0034762456',  
    },
	
	{
    sousId: 2,
    stakingToken: tokens.pty,
    earningToken: tokens.mofi,
    contractAddress: {
      97: '',
      56: '0xbad71b72acf97e01f55dde4394ef08be16ba21be',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0034762456',  
    },
  
  
]

export default pools
